<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-center">
            <v-col cols="12" sm="10" md="8" lg="6">

                <v-card class="pa-2 pa-md-4">
                    <v-card-text class="text-body-1 text--primary text-hyphenate">
                        <section>
                            <header class="text-h4">
                                Benutzungs&shy;bedingungen Modul Zuzahlungsprüfung
                            </header>
                            <p class="text-justify">
                                Das GFS-Serviceportal ermöglicht dem Teilnehmer im Modul „Zuzahlungsprüfung“ die Prüfung der Zuzahlungspflicht einer
                                versicherten Person bei einem Teilnehmenden KVT, der die Leistung dieses Moduls anbietet. Mit Anwahl dieses Moduls durch
                                den Teilnehmer gilt im Verhältnis zwischen dem Teilnehmer und den die Leistungen dieses Moduls anbietenden Teilnehmenden
                                KVTn Folgendes:
                            </p>
                            <ol class="pt-2">
                                <li>
                                    Der Teilnehmer kann durch Eingabe der Versichertennummer den aktuellen Zuzahlungsstatus des Versicherten prüfen.
                                </li>
                                <li>
                                    Der Teilnehmer stellt sicher, dass er datenschutzrechtlich zur Erhebung bzw. zum Empfang des Zahlungsstatus eines
                                    Versicherten berechtigt ist.
                                </li>

                            </ol>
                        </section>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: 'Terms',
    }
</script>
<style scoped>
    /*#region Gliederung und Überschriften*/
    section:not(:last-of-type) {
        margin-bottom: 24px;
    }

    section > header {
        margin-bottom: 8px;
        display: flex;
    }
    /*#endregion*/

    /*#region Listen mit einzelnen Punkten*/
    ol {
        counter-reset: paragraph;
        list-style: none;
        padding-left: 2em !important;
    }

    ol > li {
        position: relative;
        text-align: start;
    }

    ol > li:not(:last-of-type) {
        margin-bottom: 8px;
    }

    ol > li::before {
        content: "(" counter(paragraph) ") ";
        counter-increment: paragraph;
        position: absolute;
        left: -2em;
    }
    /*#endregion*/
</style>


